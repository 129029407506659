import getLuxLoyaltyPointBurnCalculationKey from 'checkout/lib/utils/luxLoyalty/getLuxLoyaltyPointBurnCalculationKey'
import { EmptyArray, sortBy, sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getAllItemsDiscountTotals from '../payment/getAllItemsDiscountTotals'
import getCreditPayAmount from '../payment/getCreditPayAmount'
import getAllItemsSummaryTitles from '../view/getAllItemsSummaryTitles'
import getAllItemViews from '../view/getAllItemViews'
import getCheckoutLuxLoyaltyPointsBurnCalculationRequests from './getCheckoutLuxLoyaltyPointsBurnCalculationRequests'

const LOADING_VALUE: App.StatefulData<App.Checkout.LuxLoyaltyPointBurnCalculationView> = {
  fetching: true,
}
const EMPTY_VALUE: App.StatefulData<App.Checkout.LuxLoyaltyPointBurnCalculationView> = {
  fetching: false,
  data: {
    burnableItems: EmptyArray,
    nonBurnableItems: EmptyArray,
    itemsTotalPriceInPoints: 0,
    maxBurnablePoints: 0,
    minBurnablePoints: 0,
  },
}

/**
 * View items are sorted by the highest point-to-currency ratio in desc order.
 */
const getCheckoutLuxLoyaltyPointBurnCalculationView = createSelector(
  (state: App.State) => state.luxLoyalty.account,
  (state: App.State) => getCheckoutLuxLoyaltyPointsBurnCalculationRequests(state),
  (state: App.State) => state.luxLoyalty.pointsBurnCalculations,
  (state: App.State) => getAllItemViews(state),
  (state: App.State) => getAllItemsSummaryTitles(state),
  (state: App.State) => getAllItemsDiscountTotals(state),
  (state: App.State) => getCreditPayAmount(state),
  (
    luxLoyaltyAccount,
    pointsBurnCalculationRequests,
    pointsBurnCalculations,
    allItemViews,
    allItemsSummaryTitles,
    allItemsDiscountTotals,
    creditPaymentAmount,
  ): App.StatefulData<App.Checkout.LuxLoyaltyPointBurnCalculationView> => {
    if (luxLoyaltyAccount.initial || luxLoyaltyAccount.fetching) return LOADING_VALUE
    if (!luxLoyaltyAccount.data) return EMPTY_VALUE
    if (!allItemViews.hasRequiredData) return LOADING_VALUE
    if (!pointsBurnCalculationRequests.length) return EMPTY_VALUE

    const calculationKey = getLuxLoyaltyPointBurnCalculationKey(pointsBurnCalculationRequests)
    const pointsBurnCalculation = pointsBurnCalculations[calculationKey]
    if (!pointsBurnCalculation?.data) return LOADING_VALUE

    let burnableItems: Array<App.Checkout.LuxLoyaltyPointBurnCalculationViewBurnableItem> = []
    const nonBurnableItems: Array<App.Checkout.LuxLoyaltyPointBurnCalculationViewBurnableItem> = []
    pointsBurnCalculation.data.items.forEach((calcItem) => {
      const itemView = allItemViews.data.get(calcItem.itemId)?.data
      if (itemView) {
        const actualPriceInCurrency = calcItem.priceInCurrency
        const actualPriceInPoints = calcItem.priceInPoints ?? 0
        let payablePriceInCurrency = actualPriceInCurrency
        let payablePriceInPoints = actualPriceInPoints
        const itemDiscount = allItemsDiscountTotals.get(itemView.item.itemId)
        if (itemDiscount) {
          payablePriceInCurrency -= itemDiscount
          const burnRatio = actualPriceInPoints / actualPriceInCurrency
          payablePriceInPoints = Math.round(payablePriceInCurrency * burnRatio)
        }
        const item: App.Checkout.LuxLoyaltyPointBurnCalculationViewBurnableItem = {
          itemView,
          payablePriceInCurrency,
          actualPriceInCurrency,
          payablePriceInPoints,
          actualPriceInPoints,
          titles: allItemsSummaryTitles.get(itemView.item.itemId) ?? EmptyArray,
        }
        if (item.payablePriceInPoints) burnableItems.push(item)
        else nonBurnableItems.push(item)
      }
    }, [])

    if (creditPaymentAmount) {
      // sort from worst to best burn ratio to start applying credits to the worst ones first
      const preCreditsBurnableItems = sortBy(
        burnableItems,
        (i) => i.payablePriceInPoints / i.payablePriceInCurrency,
        'desc',
      )
      const adjustedBurnableItems: Array<App.Checkout.LuxLoyaltyPointBurnCalculationViewBurnableItem> = []
      let creditsToApply = creditPaymentAmount
      for (const preCreditBurnableItem of preCreditsBurnableItems) {
        if (creditsToApply <= 0) {
          adjustedBurnableItems.push(preCreditBurnableItem)
          continue
        }
        const creditsAppliedToItemPrice = Math.min(
          creditsToApply,
          preCreditBurnableItem.payablePriceInCurrency,
        )

        if (creditsAppliedToItemPrice === preCreditBurnableItem.payablePriceInCurrency) {
          nonBurnableItems.push(preCreditBurnableItem)
        } else {
          const adjustedPriceInCurrency = preCreditBurnableItem.payablePriceInCurrency - creditsAppliedToItemPrice
          const adjustPriceInPoints = Math.round((adjustedPriceInCurrency * preCreditBurnableItem.payablePriceInPoints) / preCreditBurnableItem.payablePriceInCurrency)
          adjustedBurnableItems.push({
            ...preCreditBurnableItem,
            payablePriceInCurrency: adjustedPriceInCurrency,
            payablePriceInPoints: adjustPriceInPoints,
          })
        }
        creditsToApply -= creditsAppliedToItemPrice
      }
      burnableItems = adjustedBurnableItems
    }

    const itemsTotalPriceInPoints = sum(burnableItems, (item) => item.payablePriceInPoints)

    const maxBurnablePoints = Math.round(Math.min(
      luxLoyaltyAccount.data.points,
      itemsTotalPriceInPoints,
    ))

    return {
      fetching: false,
      data: {
        burnableItems: sortBy(
          burnableItems,
          // sort by point-to-currency ratio
          (i) => i.payablePriceInPoints / i.payablePriceInCurrency,
          'desc',
        ),
        nonBurnableItems,
        itemsTotalPriceInPoints,
        maxBurnablePoints,
        minBurnablePoints: pointsBurnCalculation.data.minBurnablePoints,
      },
    }
  },
)

export default getCheckoutLuxLoyaltyPointBurnCalculationView
