import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import React, { useMemo } from 'react'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import OfferBadgeLabel from 'components/Common/OfferBadgeLabel/OfferBadgeLabel'
import VillaCardInclusions from './VillaCardInclusions'
import LEOfferPriceDetails from './LEOfferPriceDetails'
import VillaLocationCaption from './VillaLocation'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import BookingsCountLabel from 'components/Common/Labels/BookingsCountLabel'
import { generateLuxLoyaltyPointsCalculatorVillasOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPriceNew'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'

interface Props {
  offer: App.VillaOffer | App.VillaOfferSummary;
  onClick: () => void
  offerUrl: string;
  className?: string;
  size: 'small' | 'medium';
}

function VillaOfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
  } = props

  const pkg = offer.lowestPricePackage
  const {
    default: defaultPricing,
    member: memberPricing,
  } = useOfferPackagePrice(pkg as App.Package, offer)

  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(() => {
    if (offer.bundledWithFlightsOnly) {
      return []
    }
    const propertyFees = defaultPricing.fees?.find((fee) => fee.type === 'property')?.amount ?? 0
    return [
      generateLuxLoyaltyPointsCalculatorVillasOptions(offer, {
        price: defaultPricing.price,
        memberPrice: memberPricing?.price,
        pkg,
        propertyFees,
      }),
    ]
  }, [pkg, defaultPricing.price, memberPricing?.price, offer, defaultPricing.fees])

  if (offer.isSoldOut) return null

  if (size === 'medium') {
    return <CarouselCardMedium
      className={className}
      bookmarkButton={<BookmarkButton offer={offer} />}
      image={offer.image}
      location={<VillaLocationCaption offer={offer} />}
      title={offer.name}
      priceDetails={<LEOfferPriceDetails variant="condensed" offer={offer} />}
      to={offerUrl}
      rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
      description={<VillaCardInclusions offer={offer} offerUrl={offerUrl} />}
      urgencyLabels={<LabelGroup>
        <OfferLabels offer={offer} />
        <BookingsCountLabel
          bookings={offer.stats.booked}
          threshold="offer"
        />
        {offer.badge && <OfferBadgeLabel badge={offer.badge} />}
      </LabelGroup>}
      onClick={onClick}
      loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
    />
  } else if (size === 'small') {
    return <CarouselCardSmall
      className={className}
      bookmarkButton={<BookmarkButton offer={offer} />}
      description={<VillaCardInclusions offer={offer} offerUrl={offerUrl} />}
      image={offer.image}
      location={<VillaLocationCaption offer={offer} />}
      title={offer.property.name}
      priceDetails={<LEOfferPriceDetails offer={offer} />}
      to={offerUrl}
      productType={offer.productType}
      rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
      urgencyLabels={<LabelGroup>
        <OfferLabels offer={offer} />
        <BookingsCountLabel
          bookings={offer.stats.booked}
          threshold="offer"
        />
        {offer.badge && <OfferBadgeLabel badge={offer.badge} />}
      </LabelGroup>}
      onClick={onClick}
      loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
    />
  }
  return null
}

export default VillaOfferCard
