import React, { useMemo } from 'react'
import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import CruisePriceDetails from './CruisePriceDetails'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import { getLocationString } from 'checkout/lib/utils/accommodation/location'
import CruiseDescription from './CruiseDescription'
import CruisesUrgencyTags from 'components/Cruises/CruisesUrgencyTags'
import { getCruiseOfferPriceVariationNew } from 'lib/cruises/cruiseUtils'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import useCruiseOfferView from 'hooks/Cruise/useCruiseOfferView'
import CruiseLuxPlusOnboardCreditLabel from 'components/Cruises/CruiseInclusion/CruiseLuxPlusOnboardCreditLabel'
import { getIsEnabledLuxPlusCruiseInclusions } from 'luxPlus/selectors/featureToggle'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import { useAppSelector } from 'hooks/reduxHooks'
import CruiseLuxLoyaltyPoints from 'components/Cruises/CruiseLuxLoyaltyPoints'

interface Props {
  offer: App.CruiseOffer;
  onClick: () => void;
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
}

function CruiseOfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
  } = props
  const { lowestOverallPriceDetails } = offer.mainDepartureDetails

  const priceDetails = useMemo(() => {
    const priceVariation =
      getCruiseOfferPriceVariationNew(offer, lowestOverallPriceDetails)

    return priceVariation.primaryPrice
  }, [offer, lowestOverallPriceDetails])

  const isUltraLux = isUltraLuxOffer(offer)
  const { luxPlusInclusions, isSpecialOffer } = useCruiseOfferView({ offer })
  const isEnabledLuxPlusCruiseInclusions = useAppSelector(getIsEnabledLuxPlusCruiseInclusions)
  const showLuxPlusLabel = isEnabledLuxPlusCruiseInclusions && offer.luxPlus.hasMemberInclusions

  return (
    <ProductPaletteProvider product={offer}>
      {size === 'medium' && <CarouselCardMedium
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        image={offer.images[0]}
        title={offer.name}
        location={getLocationString(offer)}
        to={offerUrl}
        providerName={offer.cruiseLine.name}
        onClick={onClick}
        urgencyLabels={<LabelGroup>
          {isSpecialOffer && <PromotionLabel variant="premium">
            Special offer
          </PromotionLabel>}
          {showLuxPlusLabel && <CruiseLuxPlusOnboardCreditLabel
            offer={offer}
            luxPlusInclusions={luxPlusInclusions}
          />}
          {isUltraLux && <ProductTypeLabel productType={offer.productType} />}
          <CruisesUrgencyTags tags={offer.urgencyTags} />
        </LabelGroup>}
        priceDetails={
          priceDetails && <CruisePriceDetails
            offerId={offer.id}
            variant="condensed"
            priceDetails={priceDetails}
          />
        }
        description={<CruiseDescription variant="condensed" offer={offer} />}
        loyaltyPointsElement={<CruiseLuxLoyaltyPoints
          offer={offer}
          calculationType="estimate"
        />}
      />}
      {size === 'small' && <CarouselCardSmall
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        image={offer.images[0]}
        location={getLocationString(offer)}
        title={offer.name}
        to={offerUrl}
        productType={offer.productType}
        onClick={onClick}
        urgencyLabels={<LabelGroup>
          {isSpecialOffer && <PromotionLabel variant="premium">
            Special offer
          </PromotionLabel>}

          {showLuxPlusLabel && <CruiseLuxPlusOnboardCreditLabel
            offer={offer}
            luxPlusInclusions={luxPlusInclusions}
          />}
          <CruisesUrgencyTags tags={offer.urgencyTags} />
        </LabelGroup>}
        priceDetails={
          priceDetails && <CruisePriceDetails
            offerId={offer.id}
            priceDetails={priceDetails}
          />
        }
        description={<CruiseDescription offer={offer} />}
        loyaltyPointsElement={<CruiseLuxLoyaltyPoints
          offer={offer}
          calculationType="estimate"
        />}
      />}
    </ProductPaletteProvider>
  )
}

export default CruiseOfferCard
