import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { getExperienceOfferPageURL } from 'lib/offer/offerPageURL'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import CarouselCardLoadingSkeleton from 'components/OfferList/OfferCards/CarouselCardLoadingSkeleton'
import useExperience from 'hooks/Experiences/useExperience'
import { mapExperienceToBookingView } from 'lib/experiences/experienceUtils'
import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import { ExperienceBookmarkButton } from 'tripPlanner/components/Bookmark/BookmarkButton'
import ExperienceLocationCaption from './ExperienceLocationCaption'
import ExperienceOfferPriceDetails from './ExperienceOfferPriceDetails'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import LoaderPlayStateContext from 'contexts/LoaderPlayStateContext'
import useToggle from 'hooks/useToggle'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import { calculateHasBeenInView, calculateTrackingInView, handleSkipInView } from 'components/utils/OfferInView'
import { OFFER_TRACKING_IN_VIEW_THRESHOLD } from 'constants/offerList'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import { generateLuxLoyaltyPointsCalculatorExperienceOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'

const Card = styled.div`
  display: grid;
  width: 75vw;
  height: 100%;
  flex-shrink: 0;
  min-height: ${rem(500)};

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  ${mediaQueryUp.tablet} {
    width: ${rem(320)};
  }

  ${mediaQueryUp.desktop} {
    &.size-medium {
      width: ${rem(512)};
    }
  }
`

const Loader = styled(CarouselCardLoadingSkeleton)`
  opacity: 0;
  transition: opacity 0.2s;

  &.visible {
    opacity: 1;
  }
`

interface Props {
  id: string;
  index?: number;
  lazyLoad?: boolean;
  className?: string;
  size?: 'small' | 'medium';
  onClick?: (experience?: App.ExperienceOffer) => void;
}

function ExperienceOfferCard(props: Props) {
  const { id, index, lazyLoad, className, size = 'small', onClick } = props
  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const [skipInView, setSkipInView] = useState(false)
  const [inViewRef, inView, entry] = useInView({
    rootMargin: '100px',
    threshold: [0, OFFER_TRACKING_IN_VIEW_THRESHOLD],
    skip: skipInView,
  })

  useEffect(() => {
    handleSkipInView(entry, skipInView, setSkipInView)
  }, [entry, skipInView, setSkipInView, inView])

  const hasBeenInView = useMemo(() => calculateHasBeenInView(entry), [entry])

  const trackingInView = useMemo(() => calculateTrackingInView(entry), [entry])

  const [experienceRaw] = useExperience(id, { disabled: lazyLoad && !hasBeenInView })
  const [loader,,, hideLoader] = useToggle(!!experienceRaw)

  const experience = useMemo(() => {
    return experienceRaw ? mapExperienceToBookingView(experienceRaw) : undefined
  }, [experienceRaw])

  useEffect(() => {
    if (trackingInView && experienceRaw && typeof index !== 'undefined') {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer: experienceRaw,
        position: index,
        key: EventDataKey.ExperienceImpression,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only
  }, [trackingInView, experienceRaw])

  const onOfferClick = useCallback(() => {
    if (onClick) {
      onClick(experienceRaw)
    } else if (experienceRaw && typeof index !== 'undefined') {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer: experienceRaw,
        position: index,
        key: EventDataKey.ExperienceClick,
      })
    }
  }, [experienceRaw, index, dispatchOfferListEvent, onClick])

  const pauseLoader = !hasBeenInView || !!experience

  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(() => [
    generateLuxLoyaltyPointsCalculatorExperienceOptions(experienceRaw, {}),
  ], [experienceRaw])

  return (
    <ProductPaletteProvider palette="default">
      <Card ref={inViewRef} className={`size-${size}`}>
        <LoaderPlayStateContext.Provider value={pauseLoader ? 'paused' : 'running'}>
          {loader && <Loader
            size={size}
            onTransitionEnd={hideLoader}
            className={!experience ? 'visible' : undefined}
          />}
          {experience && hasBeenInView && <>
            {size === 'medium' && <CarouselCardMedium
              className={className}
              bookmarkButton={<ExperienceBookmarkButton offer={experience.originalExperience} />}
              image={experience.image!}
              providerName={experience.originalExperience.vendor?.name}
              location={<ExperienceLocationCaption location={experience.location} />}
              title={experience.title}
              priceDetails={<ExperienceOfferPriceDetails experience={experience} variant="condensed"/>}
              to={getExperienceOfferPageURL(experience.originalExperience)}
              rating={isOfferRatingDisplayable(experience.rating) ? experience.rating : undefined}
              onClick={onOfferClick}
              loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
            />}
            {size === 'small' && <CarouselCardSmall
              className={className}
              bookmarkButton={<ExperienceBookmarkButton offer={experience.originalExperience} />}
              description={experience.description}
              image={experience.image!}
              location={<ExperienceLocationCaption location={experience.location} primaryCategory={experience.primaryCategory} />}
              title={experience.title}
              priceDetails={<ExperienceOfferPriceDetails experience={experience} />}
              to={getExperienceOfferPageURL(experience.originalExperience)}
              productType={experience.type}
              rating={isOfferRatingDisplayable(experience.rating) ? experience.rating : undefined}
              onClick={onOfferClick}
              loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
            />}
          </>}
        </LoaderPlayStateContext.Provider>
      </Card>
    </ProductPaletteProvider>
  )
}

export default ExperienceOfferCard
