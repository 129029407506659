import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import React, { useMemo } from 'react'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import BedbankOfferCardPriceDetails from './BedbankOfferCardPriceDetails'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import useBedbankRates from 'hooks/useBedbankRates'
import { useOfferRatePrice } from 'hooks/Offers/useOfferPriceNew'
import { generateLuxLoyaltyPointsCalculatorBedbankOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary | App.BedbankOfferWithRate
  onClick: () => void
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
  selectedDatesAndRoomsFilter?: App.OfferListFilters
}

function BedbankOfferCard(props: Props) {
  const {
    offer,
    onClick,
    className,
    offerUrl,
    size,
    selectedDatesAndRoomsFilter,
  } = props

  const location = [
    offer.property.address.city,
    offer.property.address.countryName,
  ]
    .filter(Boolean)
    .join(', ')

  const [{ hotelOnlyRates }] = useBedbankRates(offer.id)
  const rate = hotelOnlyRates[0]
  const { default: pricing, member: memberPricing } = useOfferRatePrice(offer, rate)

  const pointsEarnCalculationRequests = useMemo<Array<App.LuxLoyaltyPointsEarnCalculationRequest | undefined>>(() => {
    const propertyFees = pricing?.fees?.find((fee) => fee.type === 'property')?.amount ?? 0
    return [
      generateLuxLoyaltyPointsCalculatorBedbankOptions(rate, {
        price: pricing?.price,
        memberPrice: memberPricing?.price,
        propertyFees,
      }),
    ]
  }, [memberPricing?.price, pricing?.price, rate, pricing?.fees])

  return (
    <ProductPaletteProvider palette="default">
      {size === 'medium' && <CarouselCardMedium
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        image={offer.image}
        location={location}
        title={offer.name}
        providerName={offer.property.name}
        priceDetails={<BedbankOfferCardPriceDetails variant="condensed" offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter}/>}
        to={offerUrl}
        rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
        onClick={onClick}
        loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
      />}
      {size === 'small' && <CarouselCardSmall
        className={className}
        bookmarkButton={<BookmarkButton offer={offer} />}
        description={offer.amenities}
        descriptionLines={2}
        image={offer.image}
        location={location}
        title={offer.property.name}
        priceDetails={<BedbankOfferCardPriceDetails offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter}/>}
        to={offerUrl}
        productType={offer.productType}
        rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
        onClick={onClick}
        loyaltyPointsElement={<LuxLoyaltyPoints calculationRequests={pointsEarnCalculationRequests} calculationType="estimate" />}
      />}
    </ProductPaletteProvider>
  )
}

export default BedbankOfferCard
